export const MenuLinks = [
  {
    name: 'General Display',
    link: '/general-display',
  },
  {
    name: 'Pages',
    link: '/pages',
  },
  {
    name: 'Blog',
    link: '/blog',
  },
  {
    name: 'Job post',
    link: '/jobs',
  },
  {
    name: 'Job application',
    link: '/job-applications',
  },
  {
    name: 'Users',
    link: '/users',
  },
]
