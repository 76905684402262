import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { MenuLinks } from './Menu.Links';
import { history } from '../../../../App';

import styles from './Menu.module.scss';

export const Menu: FC = () => {
  const location = useLocation();

  return (
    <div className={styles['Menu']}>
      {
      MenuLinks.map(x => (
      <div key={`menu_${x.name}`} className={styles['item']} onClick={() => history.push(x.link)}>
        <span 
          className={location.pathname === x.link ? styles['active'] : ''}
        >
          {x.name}
        </span>
      </div>
      ))
      }
    </div>
  )
};
