import { Deploy } from './../interfaces/deploy.interface';
import { Http } from '../utils/Http';

export const launchDeploy = async (): Promise<Deploy> => {
  const axios = await Http.axios();
  const res = await axios.post('/rest/publish');
  const data = res.data;
  return { ...data, id: data._id };
};

export const abortDeploy = async (id: string): Promise<Deploy> => {
  const axios = await Http.axios();
  const res = await axios.post(`/rest/abort/${id}`);
  const data = res.data;
  return { ...data, id: data._id };
};
