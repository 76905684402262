import { Button } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Deploy } from '../../../../interfaces/deploy.interface';
import config from '../../../../config';

const deployStatus = config.deployStatus;

interface IDeployButton {
  currentDeploy?: Deploy | null;
  onClick: () => any;
}

export const DeployButton: FC<IDeployButton> = ({ currentDeploy, onClick }) => {
  const [buttonName, setButtonName] = useState(
    currentDeploy ? 'Abort deploy' : 'Deploy Changes'
  );

  useEffect(() => {
    const statuses = [deployStatus.completed, deployStatus.cancelled];
    setButtonName(
      currentDeploy && !statuses.includes(currentDeploy?.status!)
        ? 'Abort deploy'
        : 'Deploy Changes'
    );
  }, [currentDeploy]);

  return (
    <Button type='primary' onClick={onClick}>
      {buttonName}
    </Button>
  );
};
