import { gql, useMutation, useQuery } from '@apollo/client';
import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import { AuthTypes } from '../../auth/authReducer';
import Footer from '../../components/Footer';
import { Header } from '../../components/header';
import Spin from '../../components/Spin';
import { Deploy } from '../../interfaces/deploy.interface';
import routes from '../../routes';
import { abortDeploy, launchDeploy } from '../../services/deploy.service';
import { isJwtValid } from '../../utils/Auth.utils';
import { notifyGraphqlError } from '../../utils/errorHandler';

import config from '../../config';

const deployStatus = config.deployStatus;

const SETTING = gql`
  query Setting {
    Setting {
      id
      siteName
      blogMenuPlacement
      favicon {
        url
      }
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation ($input: ExchangeRefreshTokenInput!) {
    ExchangeRefreshToken(input: $input) {
      jwtBearer
    }
  }
`;

const LATEST_DEPLOY = gql`
  query LatestDeploy {
    Deploy {
      id
      status
      user {
        username
      }
      buildNumber
      slug
      message
    }
  }
`;

export const PrivateLayout = () => {
  const [currentDeploy, setCurrentDeploy] = useState<Deploy | null>(null);

  const { data: settingData } = useQuery(SETTING, {
    fetchPolicy: 'cache-first',
    onCompleted(data) {
      const setting = data?.Setting?.[0];
      const title = setting?.siteName ?? 'Website';
      document.title = title;

      if (setting?.favicon?.url) {
        const faviconEle = document.getElementById(
          'favicon'
        ) as HTMLLinkElement;
        if (faviconEle) {
          faviconEle.href = setting?.favicon?.url;
        }
      }
    },
  });

  const { refetch: refetchLatestDeploy } = useQuery<{ Deploy: Deploy }>(
    LATEST_DEPLOY,
    {
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const deploy = data?.Deploy;
        if (!deploy) {
          return;
        }
        const status = deploy.status.toLowerCase();
        if ([deployStatus.ok, deployStatus.inProgress].includes(status)) {
          setCurrentDeploy({ ...deploy });
        }
      },
    }
  );

  useEffect(() => {
    let intervalId: any;

    if (currentDeploy && !intervalId) {
      intervalId = setInterval(async () => {
        const { data } = await refetchLatestDeploy();
        const deploy = data?.Deploy;
        const status = deploy?.status?.toLowerCase();
        setCurrentDeploy({ ...deploy });
        switch (status) {
          case deployStatus.completed:
            clearInterval(intervalId);
            setTimeout(() => {
              setCurrentDeploy(null);
            }, 5000);
            message.success('Deploy completed!');
            break;
          case deployStatus.cancelled:
            clearInterval(intervalId);
            setTimeout(() => {
              setCurrentDeploy(null);
            }, 5000);
            break;

          default:
            break;
        }
      }, 5000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [currentDeploy, refetchLatestDeploy]);

  const handleDeploy = () => {
    if (currentDeploy) {
      cancelDeploy(currentDeploy);
    } else {
      initDeploy();
    }
  };

  const initDeploy = async () => {
    try {
      const deploy = await launchDeploy();
      setCurrentDeploy({ ...deploy });
      message.success('Deploy initiated correctly');
    } catch (err) {
      console.error(err);
      message.error('Error while initiating deploy');
    }
  };

  const cancelDeploy = async (deploy: Deploy) => {
    try {
      const res = await abortDeploy(deploy.id);
      setCurrentDeploy(null);
      message.success('Deploy cancelled correctly');
    } catch (err) {
      console.error(err);
      message.error('Error while cancelling deploy');
    }
  };

  const history = useHistory();
  const title = settingData?.Setting?.[0]?.siteName ?? 'Website';
  document.title = title;

  const { auth, dispatch } = useContext(AuthContext);
  const [refreshToken, { loading: refreshingToken }] = useMutation(
    REFRESH_TOKEN,
    {
      onCompleted(response) {
        const token = response?.ExchangeRefreshToken?.jwtBearer;
        console.log(response);
        dispatch({
          type: AuthTypes.RefreshToken,
          payload: token,
        });
      },
      onError(err) {
        notifyGraphqlError(err);
      },
    }
  );

  useEffect(() => {
    let interval: any;
    if (auth?.logged) {
      interval = setInterval(async () => {
        if (
          auth?.jwtBearer &&
          !isJwtValid(auth.jwtBearer) &&
          !refreshingToken
        ) {
          await refreshToken({
            variables: {
              input: {
                jwtRefresh: auth.jwtRefresh,
              },
            },
          });
        }
      }, 20000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }

    return () => clearInterval(interval);
  }, [auth, refreshToken, refreshingToken]);

  if (!auth || !auth.jwtBearer || !isJwtValid(auth.jwtBearer)) {
    history.replace(routes.login.path);
  }

  return (
    <>
      <Header handleDeploy={handleDeploy} currentDeploy={currentDeploy} />
      <React.Suspense fallback={<Spin />}>
        <Switch>
          {/* <Route
            exact
            path={routes.dashboard.path}
            component={routes.dashboard.component}
          /> */}
          <Route
            exact
            path={routes.generalDisplay.path}
            component={routes.generalDisplay.component}
          />
          <Route path={routes.pages.path} component={routes.pages.component} />
          <Route
            exact
            path={routes.blog.path}
            component={routes.blog.component}
          />
          <Route
            exact
            path={routes.blogCreate.path}
            component={routes.blogCreate.component}
          />
          <Route
            exact
            path={routes.blogUpdate.path(':id')}
            component={routes.blogUpdate.component}
          />
          <Route
            exact
            path={routes.jobPost.path}
            component={routes.jobPost.component}
          />
          <Route
            exact
            path={routes.jobPostCreate.path}
            component={routes.jobPostCreate.component}
          />
          <Route
            exact
            path={routes.jobPostUpdate.path(':id')}
            component={routes.jobPostUpdate.component}
          />
          <Route
            exact
            path={routes.jobApplication.path}
            component={routes.jobApplication.component}
          />
          <Route
            exact
            path={routes.users.path}
            component={routes.users.component}
          />
          <Route
            exact
            path={routes.userCreate.path}
            component={routes.userCreate.component}
          />
          <Route
            exact
            path={routes.userUpdate.path(':id')}
            component={routes.userUpdate.component}
          />

          <Redirect to={routes.generalDisplay.path} />
        </Switch>
      </React.Suspense>
      <Footer />
    </>
  );
};
