import React, { FC, useContext } from 'react';
import { Popover } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../auth/AuthContext';
import { AuthTypes } from '../../../../auth/authReducer';
import styles from './Avatar.module.scss';
import routes from '../../../../routes';

export const Content: FC = () => {
  const { auth, dispatch } = useContext(AuthContext);
  const history = useHistory();

  return (
    <div className={styles['Container']}>
      <div
        className={styles['item']}
        onClick={() => {
          if (auth?.logged) {
            dispatch({
              type: AuthTypes.Logout,
            });

            history.replace(routes.login.path);
          }
        }}
      >
        <span>Logout</span>
      </div>
    </div>
  );
};

export const HeaderAvatar: FC = () => (
  <Popover
    className={styles['avatar-popover']}
    placement='bottomRight'
    content={Content}
    trigger='click'
  >
    <Avatar />
  </Popover>
);
