import { AuthData } from '../interfaces/auth.interface';
import { clearProfile, clearToken } from '../utils/Auth.utils';

export const enum AuthTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  RefreshToken = '[Auth] RefreshToken',
}

export const authReducer = (
  state: AuthData | any = null,
  action: { type: AuthTypes; payload: AuthData }
) => {
  switch (action.type) {
    case AuthTypes.Login:
      return {
        ...action.payload,
        logged: true,
      };
    case AuthTypes.Logout:
      clearToken();
      clearProfile();
      return {
        logged: false,
      };
    case AuthTypes.RefreshToken:
      return {
        ...state,
        jwtBearer: action.payload,
      };

    default:
      return state;
  }
};
