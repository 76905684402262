import { message } from 'antd';
import { AxiosError } from 'axios';

export function notifyError(err: AxiosError) {
  const details = err?.response?.data?.details || ['Whoops!! Something went wrong. Please try again later!'];
  message.error(
    <>
      {details.map((e: string, index: number) => (
        <span style={{ display: 'block' }} key={index}>
          {e}
        </span>
      ))}
    </>
  );
};

export function notifyGraphqlError(err: any) {
  let error = err?.graphQLErrors?.[0];

  message.error(
    <>
      {error?.message ?? 'Whoops! Something happened. Please try again!'}
      {error?.details?.map((e: string, index: number) => (
        <span style={{ display: 'block' }} key={index}>
          {e}
        </span>
      ))}
    </>
  );
};

