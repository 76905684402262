import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
} from '@apollo/client';
import { createBrowserHistory } from 'history';
import { useEffect, useReducer } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import { AuthData } from './interfaces/auth.interface';
import { PrivateLayout } from './layouts/private';
import { PublicLayout } from './layouts/public';
import * as link from './link';
import { primaryColorVar } from './reactiveVariables';
import {
  clearProfile,
  clearToken,
  getAllTokens,
  isJwtValid,
  setAuthTokens,
  setProfile,
} from './utils/Auth.utils';

export const history = createBrowserHistory();

const client = new ApolloClient({
  link: ApolloLink.from([
    //link.errorLink,
    link.authLink,
    link.httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          PrimaryColor: {
            read() {
              return primaryColorVar();
            },
          },
        },
      },
    },
  }),

  defaultOptions: {
    watchQuery: {
      errorPolicy: 'ignore',
    },
  },
});

function App() {
  const init = () => {
    const notLogged: AuthData = { logged: false };
    const tokens = getAllTokens();
    if (!tokens) {
      return notLogged;
    }
    const jwtToken = tokens?.jwtBearer;
    if (!jwtToken || !isJwtValid(jwtToken)) {
      return notLogged;
    }

    return { ...tokens, logged: true };
  };

  const [auth, dispatch] = useReducer(authReducer, null, init);

  useEffect(() => {
    if (auth?.logged) {
      setAuthTokens(auth);
      setProfile(auth.username);
    } else {
      clearToken();
      clearProfile();
    }
  }, [auth]);

  useEffect(() => {
    document.title = 'Loading...';
  }, []);

  return (
    <AuthContext.Provider value={{ auth, dispatch }}>
      <ApolloProvider client={client}>
        <Router history={history}>
          <Switch>
            <Route path='/auth/*' component={PublicLayout} />
            <Route path='/*' component={PrivateLayout} />
          </Switch>
        </Router>
      </ApolloProvider>
    </AuthContext.Provider>
  );
}

//export default withAuthContext(App);
export default App;
