import jwtDecode from 'jwt-decode';

const STORE_RELEASE = 6;

function getWithVersionCheck(key: string) {
  const lStore = localStorage.getItem(key);
  if (lStore === null) {
    return null;
  }
  let rr;

  try {
    rr = JSON.parse(lStore);
  } catch (err) {
    if (!(err instanceof SyntaxError)) {
      throw err;
    }
    return null;
  }
  if (rr.release !== STORE_RELEASE) {
    return null;
  }
  if (!rr.payload) {
    return null;
  }
  // return JSON.parse(rr.payload);
  return rr.payload;
}

function setWithVersionCheck(key: string, payload: any) {
  localStorage.setItem(
    key,
    JSON.stringify({ payload, release: STORE_RELEASE })
  );
}

const TOKEN_KEY = 'apptoken';

export const setAuthTokens = ({
  jwtBearer,
  jwtRefresh,
}: {
  jwtBearer: string;
  jwtRefresh: string;
}) => setWithVersionCheck(TOKEN_KEY, { jwtBearer, jwtRefresh });

export const setToken = (jwtBearer: string) => {
  const tokens = getWithVersionCheck(TOKEN_KEY);
  tokens.jwtBearer = jwtBearer;
  setWithVersionCheck(TOKEN_KEY, JSON.stringify(tokens));
};

export const getToken = () => {
  const tokens = getWithVersionCheck(TOKEN_KEY);
  return tokens && tokens.jwtBearer;
};

export const getAllTokens = (): { jwtBearer: string; jwtRefresh: string } => {
  const tokens = getWithVersionCheck(TOKEN_KEY);
  return tokens;
};

export const getRefresh = () => {
  const tokens = getWithVersionCheck(TOKEN_KEY);
  return tokens && tokens.jwtRefresh;
};

export const clearToken = () => localStorage.removeItem(TOKEN_KEY);

const PROFILE_KEY = 'appprofile';

export const setProfile = (user: any) => setWithVersionCheck(PROFILE_KEY, user);

export const getProfile = () => getWithVersionCheck(PROFILE_KEY);

export const clearProfile = () => localStorage.removeItem(PROFILE_KEY);

export const isJwtValid = (jwt: string) => {
  if (!jwt) {
    return false;
  }
  const decoded: any = jwtDecode(jwt);
  const now = Date.now() / 1000 - 5; // minutes
  return now < decoded.exp;
};
