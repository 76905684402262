import { lazy } from 'react';

const routes = {
  login: {
    path: '/auth/login',
    component: lazy(() => import('./containers/Login'))
  },
  dashboard: {
    path: '/',
    component: lazy(() => import('./containers/Dashboard'))
  },
  generalDisplay: {
    path: '/general-display',
    component: lazy(() => import('./containers/GeneralDisplay'))
  },
  pages: {
    path: '/pages',
    component: lazy(() => import('./containers/Pages'))
  },
  blog: {
    path: '/blog',
    component: lazy(() => import('./containers/Blog'))
  },
  blogCreate: {
    path: '/blog/new',
    component: lazy(() => import('./containers/Blog/BlogForm'))
  },
  blogUpdate: {
    path: (id: string) => `/blog/${id}`,
    component: lazy(() => import('./containers/Blog/BlogForm'))
  },
  jobPost: {
    path: '/jobs',
    component: lazy(() => import('./containers/JobPost'))
  },
  jobPostCreate: {
    path: '/jobs/new',
    component: lazy(() => import('./containers/JobPost/JobPostForm'))
  },
  jobPostUpdate: {
    path: (id: string) => `/jobs/${id}`,
    component: lazy(() => import('./containers/JobPost/JobPostForm'))
  },
  jobApplication: {
    path: '/job-applications',
    component: lazy(() => import('./containers/JobApplication'))
  },
  users: {
    path: '/users',
    component: lazy(() => import('./containers/Users'))
  },
  userCreate: {
    path: '/users/new',
    component: lazy(() => import('./containers/Users/UserForm'))
  },
  userUpdate: {
    path: (id: string) => `/users/${id}`,
    component: lazy(() => import('./containers/Users/UserForm'))
  },
}

export default routes;

