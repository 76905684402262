import { Progress } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { Deploy } from '../../../../interfaces/deploy.interface';
import config from '../../../../config';

const deployStatus = config.deployStatus;

const PROGRESS_KEY = 'deploy-progress';

interface IDeployProgress {
  deploy?: Deploy | null;
}

export const DeployProgress: FC<IDeployProgress> = ({ deploy }) => {
  const currentProgress = deploy
    ? Number(localStorage.getItem(PROGRESS_KEY))
    : null;

  const [progress, setProgress] = useState<number | null>(currentProgress);
  const [progressStatus, setProgressStatus] = useState<
    'success' | 'normal' | 'exception' | 'active' | undefined
  >();

  useEffect(() => {
    localStorage.setItem(PROGRESS_KEY, progress + '');
  }, [progress]);

  useEffect(() => {
    if (deploy) {
      switch (deploy.status) {
        case deployStatus.ok:
          setProgress(() => 5);
          setProgressStatus('normal');
          break;
        case deployStatus.inProgress:
          setProgress((val) => {
            if (val! < 99) {
              setProgressStatus('active');
              return val! + 1;
            }
            return val;
          });
          break;
        case deployStatus.cancelled:
          setProgress(() => null);
          setProgressStatus('exception');
          break;
        case deployStatus.completed:
          setProgress(() => 100);
          setProgressStatus('success');
          break;
      }
    }
  }, [deploy]);

  return deploy && progress ? (
    <Progress
      type='circle'
      percent={progress}
      width={30}
      status={progressStatus}
    />
  ) : (
    <></>
  );
};
