const config = {
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  apiUrl: process.env.REACT_APP_API_URL,
  publishEnabled: process.env.REACT_APP_PUBLISH_ENABLED || false,
  colors: {
    footerBackground: '#101314',
    footerColor: '#ffffff99',
    backgroundColor: '#fafafa',
    secondaryBackgroundColor: '#12a22c',
    secondaryTextColor: '#ffffff',
  },
  defaultPadding: '',
  links: {
    custom: 'Custom',
    pageAnchor: 'PageAnchor',
  },
  roles: {
    Admin: 'Admin',
    User: 'User',
    SiteEditor: 'Site Editor',
  },
  blogMenuPlacement: {
    header: 'header',
    footer: 'footer',
    none: 'none',
  },
  buttonType: {
    button: 'button',
    link: 'link',
  },
  fontWeight: {
    bold: 'bold',
    semibold: 'semibold',
    regular: 'regular',
    light: 'light',
  },
  alignment: {
    left: 'left',
    right: 'right',
    center: 'center',
  },
  columnSubsectionType: {
    basic: 'basic',
    columns: 'columns',
  },
  deployStatus: {
    ok: 'ok',
    inProgress: 'in progress',
    completed: 'completed',
    cancelled: 'cancelled',
  },
};

export default config;
